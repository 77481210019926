import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/blog.css"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"
import {AiFillRead} from "react-icons/ai"

export const query = graphql`
  query($Slug: String!) {
    contentfulBlogPost(Slug: { eq: $Slug }) {
      Slug
      Title
      metaDescription
      readTime
      description
      featuredImage {
        file {
          url
        }
      }
      publishedDate(formatString: "MMMM Do, YYYY")
      category
      authorImage {
        file {
          url
        }
      }
      authorName {
        json
      }
      body {
        json
      }
    }
  }
`
const Post = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url =
          node.data.target.fields.file["en-US"].url + "?fm=jpg&fl=progressive"
        return <img alt={alt} src={url} />
      },
    },
  }
  
  const onload = () => {
    let link = document.getElementById("post").getElementsByTagName("a");
    for (let step = 0; step < link.length; step++){
      link[step].setAttribute("target", "_blank");
    }
    
  }
  

  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>{props.data.contentfulBlogPost.Title}</title>
        <meta name="title" content={props.data.contentfulBlogPost.Title}/>
        <meta name="description" content={props.data.contentfulBlogPost.metaDescription}/>
        <html lang='es'/>
        <link rel="canonical" href={"https://www.qualoom.es/blog/"+props.data.contentfulBlogPost.Slug}/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="article"/>
        <meta property="og:url" content={"https://www.qualoom.es/blog/"+props.data.contentfulBlogPost.Slug}/>
        <meta property="og:title" content={props.data.contentfulBlogPost.Title}/>
        <meta property="og:description" content={props.data.contentfulBlogPost.metaDescription}/>
        <meta property="og:image" content={"http:"+props.data.contentfulBlogPost.featuredImage.file.url}/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={"https://www.qualoom.es/blog/"+props.data.contentfulBlogPost.Slug}/>
        <meta property="twitter:title" content={props.data.contentfulBlogPost.Title}/>
        <meta property="twitter:description" content={props.data.contentfulBlogPost.metaDescription}/>
        <meta property="twitter:image" content={"http:"+props.data.contentfulBlogPost.featuredImage.file.url}/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

      </Helmet>
      <div className="background-post" style={{background: `url(${props.data.contentfulBlogPost.featuredImage.file.url})`,}}>
        <div className="header_div">
          <Header />
        </div>
        <div className="main">
          <div className="empty"></div>
          <div className="text">
            <h1>{props.data.contentfulBlogPost.Title}</h1>
            <p>{props.data.contentfulBlogPost.publishedDate}</p>
          </div>
        </div>
      </div>
      <div className="post" id="post" onLoad={onload}>
        <div className="post-extra-info">
          <div className="post-category">
            <p>{props.data.contentfulBlogPost.category}</p>
            <p className="read-time"><AiFillRead/>{props.data.contentfulBlogPost.readTime}</p>
          </div>
          <div className="post-author">
            <div className="author-image" style={{background: `url(${props.data.contentfulBlogPost.authorImage.file.url})`}}></div>
            <div className="author-name">
              <b>{documentToReactComponents(props.data.contentfulBlogPost.authorName.json, options)}</b>
            </div>
          </div>
        </div>
        
        {documentToReactComponents(
          props.data.contentfulBlogPost.body.json,
          options
        )}
      </div>
      <Footer />
    </div>
  )
}

export default Post
